<template>
	<div style="display: flex;">
		<v-container style="display: table-header-group;">
			<v-row v-if="itemsAll.length !== 0">
				<v-col lg="12" md="12" cols="12">
					<v-card>
						<v-col>
							<v-row> 
								<!--<s-select-definition
										label="Linea Empaque1"
										:def="1281"
										v-model="LinePacking"
									></s-select-definition>-->
								<v-col cols="6" lg="6" md="6">
									<s-select-definition label="Destino" :def="1181" v-model="TypeDestinyTk"
										@change="clickSwitch($event)">
									</s-select-definition>
								</v-col>
								<v-col cols="6" lg="6" md="6">
									<s-select-definition :dgr="dgr" label="Linea Empaque" :def="1315"
										v-model="TypeLineProcess" clearable :add="$fun.isAdmin()"
										@change="clickSwitch($event)">
									</s-select-definition>
								</v-col>
							</v-row> 
							<v-row> 
								<v-col cols="6" lg="6" md="6">
									<s-select-definition
										label="Turno de Empaque"
										:def="1278"
										v-model="TypeTurn"
										@change="clickSwitch($event)"
									></s-select-definition>
								</v-col>
								<v-col cols="6" lg="6" md="6">
									<s-date
										label="Fecha de Turno" 
										v-model="CbpBeginDate"
										
										@change="clickSwitch(event)"
									></s-date>
								</v-col>
								<!-- <v-col cols="4" class="mt-4">
									<b  style="display: contents;"><v-switch
										v-model="TypeView"
										label="Por Presentacion"
										color="red"
										hide-details
										style="display: contents;"
										@click="clickSwitch($event)"
									></v-switch></b>
									@change="clickSwitch($event)" 
								</v-col> -->
							</v-row>
							<v-row align="center" justify="center">
								<v-col lg="4" md="4" cols="12" outlined class="mb-2" v-for="item in items"
									align="center" :key="item.index">
									<v-card height="auto">
										<!-- <v-col cols="12" lg="12">
											<v-card outlined>
												<v-img
													:lazy-src="image"
													:src="item.photo"
													height="180"
													width="100%"
													contain
													class="grey darken-4"
												></v-img>
											</v-card>
										</v-col> -->
										<v-col cols="12" lg="12" style="margin-top: -20px">
											<v-card outlined height="auto">
												<h1 style="font-size: 28px;">
													<b>{{ item.CbpBalerName }}</b>
												</h1>
												<h1 style="font-size: 28px;">
													{{ item.CbpBalerPaternal + ' ' + item.CbpBalerMaternal }}
													<!-- {{ item.CbpBalerMaternal }} -->
												</h1>
											</v-card>
										</v-col>
										<v-col cols="12" lg="12" align="center" justify="center"
											style="margin-top: -20px">
											<v-card outlined color="orange" dark>
												<h1 style="font-size: 40px;" dark>
													{{ item.CbpBoxNumber + " CAJAS" }}
												</h1>
											</v-card>
										</v-col>
									</v-card>
								</v-col>
							</v-row>
						</v-col>
					</v-card>
				</v-col>
				<!-- <v-col lg="3" md="3" cols="3">
					<v-card class="mx-auto" max-width="500">
						<v-toolbar color="deep-purple accent-4" dark>
							<v-toolbar-title>Empacadores (as)</v-toolbar-title>
							<v-spacer></v-spacer>
						</v-toolbar>
						<v-list subheader> 
							<v-list-item v-for="item in itemsAll">
								<v-list-item-avatar>
									<v-icon :class="'amber'" dark v-text="'mdi-message-outline'"></v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title style="font-size: 20px;" v-text="item.CbpBalerName + ' ' + item.CbpBalerPaternal + ' ' + item.CbpBalerMaternal
										"></v-list-item-title>
									<h1 dark style="font-size: 20px;">
										{{ item.CbpBoxNumber + " CAJAS" }}
									</h1>
								</v-list-item-content>
								<v-list-item-icon>
								</v-list-item-icon>
							</v-list-item>
						</v-list>
					</v-card>
				</v-col> -->
			</v-row>
			<v-row v-if="itemsAll.length == 0">
				<v-col lg="12" md="12" cols="12">
					<v-card height="800" flat>
						<!-- <b style="display: contents;"><v-switch
							v-model="TypeView"
							label="Por Presentacion"
							color="red"
							hide-details
							style="display: contents;"
							@click="clickSwitch($event)"
						></v-switch></b> -->

						<!--<s-select-definition
								label="Linea Empaque"
								:def="1281"
								v-model="LinePacking"
							></s-select-definition>-->
						<v-row style="margin-left: 5px ;margin-right:5px">
							<v-col cols="6" lg="6" md="6">
								<s-select-definition label="Destino" :def="1181" v-model="TypeDestinyTk"
									@change="clickSwitch($event)">
								</s-select-definition>
							</v-col>
							<v-col cols="6" lg="6" md="6">
								<s-select-definition :dgr="dgr" label="Linea Empaque" :def="1315"
									v-model="TypeLineProcess" clearable :add="$fun.isAdmin()"
									@change="clickSwitch($event)">
								</s-select-definition>
							</v-col>
						</v-row>
						<v-row style="margin-left: 5px ;margin-right:5px">
							<v-col cols="6" lg="6" md="6">
									<s-select-definition
									label="Turno de Empaque"
									:def="1278"
									v-model="TypeTurn"
									@change="clickSwitch($event)"
									></s-select-definition>
							</v-col>
							<v-col cols="6" lg="6" md="6">
								<s-date
									label="Fecha de Turno" 
									v-model="CbpBeginDate"
									@change="clickSwitch($event)" 
								></s-date>
							</v-col>
						</v-row>
						<v-col cols="12" align="center">
							<b>
								<h1 style="margin-top: 5%">
									AUN NO SE HA COMENZADO A EMPACAR
								</h1>
							</b>
						</v-col>
						<v-col align="center">
							<!-- <v-img
								:lazy-src="valParamImg"
								max-height="650"
								max-width="300"
								:src="valParamImg"
							></v-img> -->
						</v-col>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import _sPrfCountingBoxesPresentationService from "../../../services/FreshProduction/PrfCountingBoxesPresentation";
import signalr from "signalr";
import _sHelper from "@/services/HelperService.js";
import _sGenParamService from "../../../services/General/ParameterService";

export default {
	data() {
		return {
			items: [],
			itemsAll: [],
			image: "",
			text: "",
			valParamImg: "",
			TypeView: false,
			LinePacking: 0,
			TypeDestinyTk: 1,
			TypeLineProcess: 1,
			TypeTurn: 1,
			dgr: 70,
			CbpBeginDate: null,
		};
	},

	watch: {
		LinePacking(newValue, oldValue) {
			this.loadData();
		},
		CbpBeginDate(){
			this.loadData();
		}
	},

		/*mounted() {
			console.log("Time1");
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

		// 	recaptchaScript.addEventListener("load", () => {
		// 		console.log("Time2");	
		// 		$.connection.hub.url = this.$const.URL + "/signalr";
		// 		var chat = $.connection.notification;
		// 		chat.client.SendMessageTagCountPacking = group => {
		// 			this.loadData();
		// 			console.log("Prueba01");
		// 		};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "updateCountPacking");
					console.log("Prueba02");
				});
		}, */
	methods:{
		Initialize() {
			let USA = this.$fun.hasSpecialPermission('USA');
			let EUR = this.$fun.hasSpecialPermission('EUR');
			let KOR = this.$fun.hasSpecialPermission('KOR');

			if (USA) {
				this.LinePacking = 3;
			}
			if (EUR) {
				this.LinePacking = 2;
			}
			if (KOR) {
				this.LinePacking = 9;
			}

		},

		loadParameter() {
			_sGenParamService
				.search({ PrmName: "ImagenFondoEmpaque" }, this.$fun.getUserID())
				.then(resp => {
					if (resp.status == 200) {
						this.valParamImg = resp.data.PrmValue;
					}
				});
		},

		clickSwitch(val) {
			this.loadData();
		},

		loadData() {
			console.log("data", this.itemsAll);
			var type = 2;
			if (this.TypeView) {
				type = 1;
			} else {
				type = 2;
			}

			let filters = {
				TypeView: 	type,
				LinePacking: 	this.LinePacking,
				TypeDestiny: 	this.TypeDestinyTk,
				TypeLine: 		this.TypeLineProcess,
				TypeTurn : 		this.TypeTurn,
				CbpBeginDate: 	this.CbpBeginDate == null ? this.$fun.getDate() : this.CbpBeginDate,
			}

			console.log('fechaaaaa ', this.$fun.getDate() );
			console.log('fffffffffffffffff ', filters);

			_sPrfCountingBoxesPresentationService
				.packingboxes(filters, this.$fun.getUserID())
				.then(resp => {
					if (resp.status == 200) {
						this.itemsAll = resp.data;
						if (this.itemsAll.length > 0) {
							this.items = resp.data[0].details;
						}
						console.log("data", resp.data);
						/* this.items.map(element => {
							_sHelper
								.getPhotoWorker(
									element.CbpNumberDocument,
									this.$fun.getUserID(),
								)
								.then(resp => {
									if (resp.status == 200) {
										const blob = new Blob([resp.data], {
											type: "image/jpg",
										});

										let image = blob;
										var reader = new FileReader();
										reader.readAsDataURL(image);
										reader.onloadend = () => {
											element.photo = reader.result;
											this.image = reader.result;
										};
									}
								});
						}); */
					}
				});
		},
	},

	created() {
		this.loadData();
		this.loadParameter();
		this.Initialize();
	},

	mounted() {
		let recaptchaScript = document.createElement("script");
		recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
		document.head.appendChild(recaptchaScript);

		recaptchaScript.addEventListener("load", () => {
			$.connection.hub.url = this.$const.URL + "/signalr";
			var chat = $.connection.notification;
			chat.client.SendMessageTagCountPacking = group => {
				this.loadData();
			};

			$.connection.hub.start({ jsonp: true }).done(e => {
				chat.invoke("addToGroup", "updateCountPacking");
			});
		});
	},
};
</script>

<style scoped></style>
